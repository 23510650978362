function getRandomNumber(min, max){
    return Math.floor(Math.random() * (max - min + 1) + min);
}

// 洗牌函数
export function shuffle(arr){
    for(let i = 0;i<arr.length; i++){
      const j = getRandomNumber(0, i);
      console.log(j)
      const temp = arr[i];
      arr[i] = arr[j];
      arr[j] = temp;
    }
    return arr;
}
// 罗澜返回的UTC时间转北京时间（new Date格式）
export function UTCtransform(utcString){
  let UTCTime = new Date(utcString+'Z');
  return new Date(UTCTime.setHours(UTCTime.getUTCHours()+ 8));
}